import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">


      <div className="display-3 font-200 text-primary">
        <span className="text-muted">404</span> :.-(
          <br/>
          <small className="text-white font-200">Page not found</small>
      </div>
      <div className="row my-4">
        <div className="col-md-6 offset-md-3 text-center">
          <p className="lead font-200 text-white">
          Sorry, this page does not exists
          <hr/>
          <div className="text-center mt-5 mb-5">
            <img src={logo} width="80" height="80" className="" alt="inmagik logo"/>
          </div>
          Visit our <Link to="/">homepage</Link> to get started.
          </p>
        </div>
      </div> 

    <div className="main-content container p-2">
        
 
  
    </div>
  </div>
  </Layout>
)

export default NotFoundPage
